import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import VueSessionStorage from 'vue-sessionstorage'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/LandingPage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const ticket = to.query.ticket
  //   if(to.path === "/" && ticket) {
  //     store.dispatch('validateServiceTicket',ticket)
  //     next('/')  
  // }
  if (ticket) {
    store.dispatch('validateServiceTicket', ticket)
      .then(response => {
        if (response) {
          window.history.replaceState({}, document.title, window.location.origin + '/')
          window.location.reload()
        }
      })
  }

  next()
})
export default router