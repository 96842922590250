let url = () =>{
    let url
    if(process.env.NODE_ENV === 'development') {
        //  = `http://139.162.234.164:81`
        url = `https://staging.afcfta.app`
        // url = `https://main.afcfta.app` 
    } else {
        // url = `http://139.162.234.164:81`
        // staging url
        url = `https://staging.afcfta.app` 
    
       //deployment url
        // url = `https://main.afcfta.app`    
    }
    return url
}

export default url;