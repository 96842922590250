import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueSessionStorage from 'vue-sessionstorage'
import Toasted from 'vue-toasted';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import Pagination from 'vue-pagination-2';
import '@fortawesome/fontawesome-free/css/all.css'     
import '@fortawesome/fontawesome-free/js/all.js'


Vue.use(Toasted)
Vue.use(VueSessionStorage)
Vue.component('pagination', Pagination)
Vue.component(PulseLoader)
Vue.component('multiselect', Multiselect)
Vue.config.productionTip = false

Vue.filter('formatDate',function(value,formatType = 'LL'){
  if(!value) return ''
  return moment(value).format(formatType)
})

Vue.filter('FromNow',(value)=>{
  if(!value) return ''

  return moment(value).fromNow()
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
